import { Link } from 'gatsby';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import * as styles from './btn.module.css';

export default function Btn({
    icon,
    href,
    to,
    label,
    labelStyle,
    labelClassName,
    children,
    className,
    style,
    onClick,
    disabled
}) {
    const iconEl = useRef(null);
    const wrapperEl = useRef(null);

    useEffect(() => {
        let iconWidth = 'unset';
        if (iconEl.current !== null) iconWidth = `${iconEl.current.offsetWidth}px`;
        wrapperEl.current.style.setProperty('--icon-width', iconWidth);
    }, []);

    const commonWrapperProps = {
        className: `flex justify-around items-center ${styles.btn} ${className} ${disabled && 'pointer-events-none brightness-90 shadow-inner'}`,
        style
    }

    const innerContent = <>
        { icon && <i ref={iconEl} style={labelStyle} className={ [icon, styles.icon, labelClassName].join(' ') }></i> }
        { label && <span style={ labelStyle } className={ `${styles.label} ${labelClassName} flex-grow flex justify-center` }> { label } </span> }
        { children && <div className='flex-grow flex justify-center'>
            { children }
        </div> }
    </>

    return (
        href === undefined && to === undefined
        ? <button ref={wrapperEl} onClick={onClick} {...commonWrapperProps}> { innerContent } </button>
        : <span ref={wrapperEl}> <Link to={to ?? href} {...commonWrapperProps}> { innerContent } </Link> </span>
    )
}