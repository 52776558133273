import React from 'react';
import * as styles from './divet.module.css';

export default function Divet({ children, style, className }) {
    return (
        <div style={style} className={`${className} ${styles.divet}`}>
            { children }
        </div>
    )

}