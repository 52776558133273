import * as React from 'react';
import '../builder';
import { BuilderComponent } from '@builder.io/react';
import '@builder.io/widgets';

export default function PageTemplate() {
  return <>
    <h1>Editing...</h1>
    <BuilderComponent model="page" />
  </>
};