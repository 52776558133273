import React from "react";
import { formatPrice } from "../../../utils/format-price";

const DEFAULTS = {
    isSimplePrice: false,
    flip: false
};

export default function Price(props) {
    const {
        price,
        comparisonPrice,
        isSimplePrice,
        flip
    } = Object.assign({}, DEFAULTS, props);
    
    return <span className={`inline-flex ${flip && 'flex-row-reverse'}`}>
        { comparisonPrice != null
            ? <>
                <span className=' text-orange-600 mx-2'>
                    { isSimplePrice ? `$${price}` : formatPrice(price.currencyCode, price.amount) }
                </span>
                <span className=' decoration-orange-600 line-through'>
                    { isSimplePrice ? `$${comparisonPrice}` : formatPrice(comparisonPrice.currencyCode, comparisonPrice.amount) }
                </span>
            </>
            : <>
                <span>
                    { isSimplePrice ? `$${price}` : formatPrice(price.currencyCode, price.amount) }
                </span>
            </>
        }
    </span>
}